import styled from 'styled-components'

import { Button, theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

import { KnowledgeBaseItemCardProps } from './types'

export const CardItem = styled.div<KnowledgeBaseItemCardProps>`
	display: block;
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.white};
	padding: ${(props) => (props.hasBorder ? `24px 22px 16px` : `24px 0 16px`)};
	position: relative;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: ${(props) => (props.hasBorder ? `22px` : `0px`)};
		right: ${(props) => (props.hasBorder ? `22px` : `0px`)};
		height: 1px;
		background-color: ${theme.colors.greyAccentLight};
		display: ${(props) => (props.isLast ? `none` : `block`)};
	}

	@media ${theme.breakpoints.sm} {
		display: inline-block;
		padding: ${(props) => (props.hasBorder ? `24px` : `24px 0 16px`)};
		border-radius: ${(props) => (props.hasBorder ? `5px` : `0px`)};
		box-shadow: ${(props) =>
			props.hasBorder
				? `0 0 2px 0 ${hexToRgb(theme.colors.marineBlack, {
						alpha: 0.12,
						format: 'css'
				  })}, 0 0 3px 0 ${hexToRgb(theme.colors.marineBlack, {
						alpha: 0.1,
						format: 'css'
				  })}, 0 2px 4px 0 ${hexToRgb(theme.colors.marineBlack, {
						alpha: 0.08,
						format: 'css'
				  })}`
				: `none`};
		border-bottom: 0;
	}

	@media ${theme.breakpoints.md} {
		padding: ${(props) =>
			props.hasBorder ? `32px 32px 24px` : `12px 20px;`};

		&::before {
			display: none;
		}
	}

	@media ${theme.breakpoints.lg} {
		padding: ${(props) =>
			props.hasBorder ? `32px 32px 24px` : `0 30px 0`};
	}
`

export const CardThumbnail = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	background-color: ${theme.colors.greyAccentLight};
	margin-bottom: 24px;
	cursor: pointer;
	width: 100%;
`

export const CardThumbnailImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
`

export const CardButton = styled.div`
	position: absolute;
	width: 56px;
	height: 56px;
	overflow: hidden;
	border-radius: 100%;
	display: flex;
	background-color: ${theme.colors.blue};
	top: 50%;
	left: 50%;
	margin-left: -28px;
	margin-top: -28px;

	&::before {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 9px 0 9px 15px;
		border-color: transparent transparent transparent ${theme.colors.white};
		margin: auto;
	}
`

export const CardItemDuration = styled.p`
	display: inline-flex;
	margin: 0 0 8px;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
	color: ${theme.colors.grey};

	svg {
		margin: auto auto auto 0;
		position: relative;
		top: -1px;
	}

	span {
		margin-left: 8px;
	}
`

export const VideoCardItemText = styled.p`
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0.44px;
	color: ${theme.colors.marineBlack};
`

export const DownloadCardItemText = styled.p<KnowledgeBaseItemCardProps>`
	margin: ${(props) => (props.hasBorder ? `0 0 26px` : `0 0 24px`)};
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
	color: ${theme.colors.marineBlack};

	@media ${theme.breakpoints.sm} {
		flex-grow: 1;
		flex-shrink: 1;
	}
`

export const CardItemButton = styled(Button.Default)`
	font-size: 14px;

	@media ${theme.breakpoints.md} {
		flex-grow: 0;
		flex-shrink: 0;
		margin: auto 0 0;
	}
`

export const CardItemTags = styled.ul`
	border-top: 1px solid ${theme.colors.greyAccentLight};
	margin: 24px 0 0;
	padding: 16px 0 0;
	overflow: hidden;
	flex-wrap: wrap;
	display: flex;
`

export const CardItemTagsItem = styled.li`
	color: ${theme.colors.grey};
	font-size: 14px;
	line-height: 30px;
	flex-grow: 0;
	flex-shrink: 0;

	span {
		display: inline-block;
		margin: 0 12px 0 0;
		padding: 0 8px;
		background-color: ${theme.colors.blueIce};
		color: ${theme.colors.marineBlack};
		font-size: 12px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.5px;
		white-space: nowrap;
	}
`
